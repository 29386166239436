<template>
  <div class="card questions">
    <div class="card-header">
      <h3 class="card-title">{{ $t('page.title.lesson_details') }}: <span
          v-if="lesson">{{ lesson.name }}</span></h3>
      <div class="float-right">
        <router-link :to="{'name':'lessons-list', 'params': {masterClassId: this.masterClassId}}">
          <b-button size="sm" variant="outline-primary">{{ $t('btn.back') }}</b-button>
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <b-card v-if="lesson">

            <b-card-header><b>{{ $t('page.general_information') }}</b></b-card-header>
            <b-card-body>
              <label>Id</label>
              <p>{{ lesson.id }}</p>

              <label>{{ $t('page.lesson.name') }}</label>
              <p>
                <input v-model="formEdit.name" v-if="isEdit" class="form-control">
                <span v-else>{{ lesson.name }}</span>
              </p>

              <label>{{ $t('page.description') }}</label>
              <p>
                <input v-model="formEdit.description" v-if="isEdit" class="form-control">
                <span v-else>{{ lesson.description }}</span>
              </p>

              <label>{{ $t('page.status') }}</label>
              <p>
                <vue-select
                    v-if="isEdit"
                    v-model="formEdit.status"
                    :options="statuses" :reduce="status => status.value"
                    label="text"
                ></vue-select>
                <span v-else>{{ lesson.status }}</span>
              </p>

              <label>{{ $t('page.lesson.length') }}</label>
              <p>
                <input v-model="formEdit.length" v-if="isEdit" class="form-control"  type="number">
                <span v-else>{{ lesson.length }}</span>
              </p>

              <label>{{ $t('page.lesson.number') }}</label>
              <p>
                <input v-model="formEdit.number" v-if="isEdit" class="form-control" type="number">
                <span v-else>{{ lesson.number }}</span>
              </p>

              <label>{{ $t('page.lesson.scoreVideo') }}</label>
              <p>
                <input v-model="formEdit.scoreVideo" v-if="isEdit" class="form-control">
                <span v-else>{{ lesson.scoreVideo ?? 'brak' }}</span>
              </p>

              <label>{{ $t('page.lesson.plays') }}</label>
              <p>
                <span>{{ lesson.plays }}</span>
              </p>

              <label>{{ $t('page.lesson.averageRate') }}</label>
              <p>
                <span>{{ lesson.averageRate }}</span>
              </p>


            </b-card-body>
          </b-card>
        </div>
        <div class="col-6">
          <b-card v-if="lesson">
            <b-card-header><b>{{ $t('page.lesson.movie') }}</b></b-card-header>
            <b-card-body>
              <label>{{ $t('page.lesson.movie') }}</label>
              <p>
                <input v-model="formEdit.movie" v-if="isEdit" class="form-control">
                <span v-else>{{ lesson.movie }}</span>
              </p>
              <vimeo v-if="lesson.movie && !isEdit" :vimeo-id="lesson.movie" title=""></vimeo>
              <vimeo v-if="formEdit.movie && isEdit" :vimeo-id="formEdit.movie" title=""></vimeo>

              <label>{{ $t('page.lesson.movieWithSubtitles') }}</label>
              <p>
                <input v-model="formEdit.movieWithSubtitles" v-if="isEdit" class="form-control">
                <span v-else>{{ lesson.movieWithSubtitles }}</span>
              </p>

              <vimeo v-if="lesson.movieWithSubtitles && !isEdit" :vimeo-id="lesson.movieWithSubtitles" title=""></vimeo>
              <vimeo v-if="formEdit.movieWithSubtitles && isEdit" :vimeo-id="formEdit.movieWithSubtitles" title=""></vimeo>

              <label>{{ $t('page.lesson.movieWithSignLanguage') }}</label>
              <p>
                <input v-model="formEdit.movieWithSignLanguage" v-if="isEdit" class="form-control">
                <span v-else>{{ lesson.movieWithSignLanguage }}</span>
              </p>

              <vimeo v-if="lesson.movieWithSignLanguage && !isEdit" :vimeo-id="lesson.movieWithSignLanguage" title=""></vimeo>
              <vimeo v-if="formEdit.movieWithSignLanguage && isEdit" :vimeo-id="formEdit.movieWithSignLanguage" title=""></vimeo>

              <label>{{ $t('page.lesson.movieWithAudioDescription') }}</label>
              <p>
                <input v-model="formEdit.movieWithAudioDescription" v-if="isEdit" class="form-control">
                <span v-else>{{ lesson.movieWithAudioDescription }}</span>
              </p>

              <vimeo v-if="lesson.movieWithAudioDescription && !isEdit" :vimeo-id="lesson.movieWithAudioDescription" title=""></vimeo>
              <vimeo v-if="formEdit.movieWithAudioDescription && isEdit" :vimeo-id="formEdit.movieWithAudioDescription" title=""></vimeo>

            </b-card-body>
          </b-card>
        </div>

      </div>
      <div class="buttons float-right">
        <b-button v-if="!isEdit" type="button" @click="startEdit()" variant="success" class="mr-1">{{ $t('actions.edit') }}</b-button>
        <b-button v-if="isEdit" type="button" @click="changeData()" variant="success" class="mr-1">{{ $t('actions.save') }}</b-button>
        <b-button v-if="isEdit" type="button" @click="isEdit = false" variant="danger" class="mr-1">{{ $t('actions.cancel') }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>

import alertify from 'alertifyjs';
import axios from "axios";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { VueSelect } from "vue-select";
import "vue-select/dist/vue-select.css";
import Vimeo from "@/components/vimeo/Vimeo.vue";

export default {
  props: ['apiKey'],
  components: {
    VueSelect,
    Vimeo
  },
  data() {
    return {
      masterClassId: null,
      lessonId: null,
      formEdit: {},
      isEdit: false,
      selectedAuthors: [],
      statuses: [
        {value: 'active', text: 'Active'},
        {value: 'inactive', text: 'Inactive'},
        {value: 'bonus', text: 'Bonus'},
        {value: 'deleted', text: 'Deleted'},
      ],
    };
  },
  mounted() {
    this.getLesson();
    this.getMasterClassName();
  },
  computed: {
    masterClassName: function () {
      return this.$store.getters["lessonsStore/masterClassName"];
    },
    lesson: function () {
      return this.$store.getters["lessonsStore/lesson"];
    },
  },
  beforeDestroy() {
    this.$store.commit('lessonsStore/setLesson', null);
  },
  created() {
    this.masterClassId = this.$route.params.masterClassId;
    this.lessonId = this.$route.params.id;
  },
  methods: {
    startEdit() {
      this.formEdit = JSON.parse(JSON.stringify(this.lesson));
      this.isEdit = true;
    },
    getMasterClassName() {
      this.$store.dispatch('lessonsStore/getMasterClassName', this.masterClassId);
    },
    getLesson() {
      this.$store.dispatch('lessonsStore/getLesson', this.lessonId);
    },
    changeData() {
      const url = '/lesson/' + this.lessonId +'/edit';
      this.formEdit.masterClassId = this.masterClassId;
      this.formEdit.length = parseInt(this.formEdit.length);
      this.formEdit.number = parseInt(this.formEdit.number);

      axios.post(url, this.formEdit)
          .then(() => {
            alertify.success('Lekcja została zmieniona');
            this.getLesson();
            this.isEdit = false;
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
  },
  watch: {
    $route() {
    }
  }
}
</script>

